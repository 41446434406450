import * as React from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import "../styles/main.scss"
import { Helmet } from "react-helmet"

export const PodcastPageTemplate = ({ title, description }) => {
  return (
    <div className="container sectionThree">
      <div className="content">
        <div className="staticImageHolder">
          <StaticImage
            src="../images/podcast1.png"
            placeholder="blurred"
            alt="Juicy Jogger Quote, No Matter How Slow You Go, You are Still Lapping People on The Couch"
            layout="fullWidth"
          />
        </div>
        <h2 style={{ marginTop: "20px" }}>{title}</h2>
        <p>{description}</p>
        <a
          target="_blank"
          href="https://dedicated-founder-2746.ck.page/02a94ba0d8"
        >
          <div class="pointer">
            <p>Subscribe To Newsletter</p>
          </div>
        </a>
        <a target="_blank" href="https://michelpasvar.bigcartel.com/">
          <div class="pointer">
            <p>Shop Merch</p>
          </div>
        </a>
      </div>
    </div>
  )
}

PodcastPageTemplate.propTypes = {
  headline: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
}

const PodcastPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <PodcastPageTemplate
        title={frontmatter.title}
        description={frontmatter.Description}
      />
    </Layout>
  )
}

PodcastPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default PodcastPage

export const pageQuery = graphql`
  query PodcastPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "podcast-page" } }) {
      frontmatter {
        title
        Description
      }
    }
  }
`
